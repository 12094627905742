.contactsAndLogin {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    justify-content: normal;
    flex-direction: column;
    max-width: 345px;

  }
  margin-top: 178px;
  display: flex;
  justify-content: space-evenly;

  .cardEmail {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      padding: 39px 54px 40px 33px;
      height: fit-content;
      width: fit-content;
      margin-bottom: 30px;


    }
    width: 378px;
    height: 312px;
    border: 2px solid #7F7F7F;
    border-radius: 22px;
    padding-left: 55px;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 24px;
        line-height: 33px;
        width: 258px;
      }

      font-family: 'Noto Sans', sans-serif;
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 30px;
      font-weight: 700;
    }

    a {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: fit-content;
        font-size: 18px;
      }
      color: #fff;
      font-family: 'Noto Sans', sans-serif;
      font-size: 24px;
    }

    a:hover {
      color: #FA00FF;

    }

  }
}

