.textsAndPhone {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    flex-direction: column-reverse;
    width: 345px;
    margin-top: 1372px;
  }
  display: flex;
  margin-top: 1288px;
  flex-direction: column;

  .phone {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-left: 0;
      width: 181px;
      height: auto;
      margin-top: -518px;
    }

    margin-left: 82px;
    align-self: center;
    margin-top: 30px;
  }

  .arrow {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-left: 260px;
      margin-bottom: 102px;
      margin-top: 390px;
    }

    margin-top: -480px;
    margin-left: 880px;

    svg {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        transform: rotate(36deg)
      }
      position: relative;
    }

    p {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 18px;
        top: 119px;
        left: -52px;
        width: 133px;
      }
      position: relative;
      top: 100px;
      left: 76px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 21px;
    }
  }

  .texts {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
      margin-top: 10px;
      align-items: normal;
    }
    margin-top: 437px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 24px;
        line-height: 29px;
        width: 345px;
      }
      margin-bottom: 23px;
      width: 381px;
    }

    h4 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
        width: 292px;
      }
      width: 320px;
    }
  }

}


