.content {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 345px;
    margin-top: 179px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -28px;

  .texts {
    text-align: center;

    h2 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 24px;
        line-height: 29px;
      }
      margin-bottom: 23px;
    }

    h4 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
        width: 345px;
      }
      width: 600px;
      margin-bottom: 82px;
    }
  }


}