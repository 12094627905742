header {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 345px;
    padding-top: 40px;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 0;
  }
  position: relative;
  z-index: 2;
  width: 1240px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 23px;


  .logo {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-left: 0;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: -15px;
    transition: 0.2s ease-in-out;

    img {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 52px;
        height: auto;
        margin-left: 0;
      }
    }

    svg {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 46px;
        margin: 0 0 0 0;
      }
      margin-left: 8px;
      margin-top: 8px;
    }
  }

  .logo:hover {
    transform: scale(1.015);

  }

  .login {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-left: 0;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 108px;
    margin-top: 8px;

    svg {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        display: none;
      }
    }

    .login_txt {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 14px;
      }
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 500;
      margin-left: 12px;
      font-size: 18px;
      color: #fff;

      .line {
        @media only screen and (min-width: 320px) and (max-width: 480px) {
          width: 38px;
        }
        cursor: pointer;
        background-color: #ffffff;
        width: 54px;
        height: 1px;
      }
    }
  }

  .login:hover .line {
    background-color: #808080;
  }

  .login:hover svg path {
    fill: #808080
  }
}