.firstScreen {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 375px;
  }
  .textsAndPhone {
    display: flex;

    .texts {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-right: 0;
      }
      margin-right: 40px;

      .title {
        @media only screen and (min-width: 320px) and (max-width: 480px) {
          font-size: 35px;
          line-height: 40px;
          width: 207px;
          margin-top: 98px;
          margin-bottom: 24px;
        }
        margin-top: 143px;
        margin-bottom: 88px;
        width: 643px;
      }

      div {
        display: flex;
        align-items: flex-end;

        svg {
          @media only screen and (min-width: 320px) and (max-width: 480px) {
            display: none;
          }
        }

        p {
          @media only screen and (min-width: 320px) and (max-width: 480px) {
            margin-left: 0;
            font-size: 16px;
            width: 240px;
            line-height: 22px;
          }
          width: 380px;
          font-family: 'Noto Sans', sans-serif;
          font-size: 20px;
          line-height: 27px;
          margin-left: 28px;
        }
      }
    }

    .phone {
      img {
        @media only screen and (min-width: 320px) and (max-width: 480px) {
          margin-left: -40px;
          margin-top: -9px;
          width: 296px;
        }
        margin-top: -131px;
      }
    }
  }

  .downloadOurApp {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
      justify-content: normal;
      width: 345px;
    }
    display: flex;
    justify-content: space-between;
    margin-top: -54px;

    .downloadText {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-top: 40px;
        font-size: 24px;
        text-align: center;
      }
      color: #FFFFFF;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 35px;
    }

    .appstoreAndGooglePlay {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-right: 0;
        margin-top: 0;
        flex-direction: column;
        align-items: center;
      }
      display: flex;
      justify-content: center;
      margin-right: 64px;
      margin-top: -7px;

      .appStoreBtn {
        @media only screen and (min-width: 320px) and (max-width: 480px) {
          margin-right: 0;
          margin-top: 34px;
          margin-bottom: 24px;
        }
        margin-right: 28px;
      }

      svg {
        cursor: pointer;
        transition: all 0.2s ease;
      }

      svg:hover {
        transform: scale(1.1);
      }
    }

  }
}



