form {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 345px;
  }
  width: 818px;
  margin-left: -4px;

  .inputArea {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      width: 345px;
      margin-bottom: 16px;
    }

    width: 818px;
    margin-bottom: 24px;
    border-radius: 10px;
    border: 1px solid #5C5C5C;
    background-color: white;

    input {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 323px;
        font-size: 16px;
        line-height: 22px;
        padding: 17px 11px;
      }
      background-color: transparent;
      width: 771px;
      border: 0;
      padding: 28px 23px;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 400;
      font-size: 18px;
      border-radius: 10px;
    }
  }

  .inputAreaError {
    border: 2px solid red;
  }

  .inputAreaMsg {
    input {
      padding-bottom: 145px;
    }
  }

  input::placeholder {
    color: #BFBFBF;
  }


  .groupInputs {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
      justify-content: normal;
    }
    display: flex;
    justify-content: space-between;

    .inputArea {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 345px;
        margin-bottom: 16px;
      }
      width: 393px;

      input {
        @media only screen and (min-width: 320px) and (max-width: 480px) {
          width: 323px;
          font-size: 16px;
          line-height: 22px;
          padding: 17px 11px;
        }
        width: 347px;
      }
    }
  }

  .btnArea {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-top: 40px;
    }
    display: flex;
    margin-top: 60px;

    .btnForm {
      cursor: pointer;
      width: 398px;
      height: 80px;
      background: #000000;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      font-weight: 700;
      margin: auto;
      transition: 0.2s ease-in-out;
    }

    .btnForm:hover {
      color: #000000;
      background: #ffffff;
    }

    .btnForm:active{
      transform: scale(0.985);
    }
  }
}