.App {
    background-color: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

p {
    color: #ffffff;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #ffffff;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 51px;
    font-weight: 700;
    line-height: 62px;
    color: #ffffff;
}

h4 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #ffffff;
}
.content {
    position: relative;
    width: 1240px;
    margin: 0 auto;
    z-index: 2;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .content{
        position: relative;
        left:0;
        top:0;
        right: 0;
        margin: 0;
        padding: 0 15px;
        max-width: 345px;
        z-index: 2;
        overflow: hidden;
    }
}

