.phoneAndText {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    flex-direction: column-reverse;
    width: 345px;
  }
  display: flex;

  .phone_pic {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      height: 317px;
      width: auto;
      margin-top: 176px;
      margin-right: -539px;
      object-fit: contain;
      transform: rotate(-90deg)
    }
    margin-top: 37px;
    margin-left: -555px;
  }

  .texts {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-top: 110px;
      margin-left: 0;

    }
    margin-top: 236px;
    margin-left: -53px;


    h2 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 24px;
        line-height: 29px;
        width: 345px;

      }
      margin-bottom: 23px;
    }

    h4 {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
      }
      width: 320px;
    }
  }
}